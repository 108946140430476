import * as React from 'react';

export function lazyImport<T extends React.ComponentType<any>>(
  factory: () => Promise<{ default: T }>,
): React.LazyExoticComponent<T> {
  return React.lazy(factory);
}

// Usage
// const Home = lazyImport(() => import("./Home"));
