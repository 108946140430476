import React, { FC, PropsWithChildren } from 'react';

import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import ErrorFallback from 'components/core/ErrorFallback';
import LoadingFallback from 'components/core/LoadingFallback';

const AppProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <React.Suspense fallback={<LoadingFallback />}>
      <ErrorBoundary fallback={<ErrorFallback />}>
        <QueryClientProvider client={new QueryClient()}>
          {children}

          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ErrorBoundary>
    </React.Suspense>
  );
};

export default AppProvider;
