import { FC, Suspense } from 'react';
import { $session } from 'stores';
import { useIsMobile } from 'hooks';
import { RoutePaths } from 'constants/routes';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import SideBar from 'components/layouts/SideBar';
import MobileNavBar from 'components/layouts/MobileNavBar';
import LoadingFallback from 'components/core/LoadingFallback';
import useClientDetails from 'queries/clientDetails';
import { Loading } from 'react-daisyui';
import $clientStore from 'stores/client/store';

const AuthGuard: FC = () => {
  const isAuth = $session.selectors.useIsAuth();
  const location = useLocation();
  const clientId = $session.selectors.useClientId();
  const { data: clientDetails, isLoading: isClientDetailsLoading } =
    useClientDetails(clientId!);
  const { pathname, search } = location;

  if (!isAuth && pathname !== RoutePaths.login) {
    const from =
      pathname === RoutePaths.landing
        ? ''
        : `?from=${encodeURIComponent(pathname)}${search.length > 0 ? `&search=${encodeURIComponent(search)}` : ''}`;
    return <Navigate to={`${RoutePaths.login}${from}`} replace />;
  }

  if (pathname === RoutePaths.landing) {
    return (
      <Navigate
        to={`${RoutePaths.shiporders}${search.length > 0 ? search : ''}`}
        replace
      />
    );
  }

  if (clientDetails) {
    $clientStore.actions.setClientData(clientDetails.data);
  }

  return null;
};

const Navigation: FC = () => {
  const isMobile = useIsMobile();
  return isMobile ? <MobileNavBar /> : <SideBar />;
};

const MainLayout: FC = () => {
  return (
    <div className="flex flex-col md:flex-row h-screen overflow-hidden">
      <Navigation />
      <div className="flex flex-1 flex-col h-full overflow-auto">
        <Suspense fallback={<LoadingFallback />}>
          <AuthGuard />
          <Outlet />
        </Suspense>
      </div>
    </div>
  );
};

export default MainLayout;
