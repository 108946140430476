import { FC } from 'react';

import Button from 'components/primitives/Button';
import { $site } from 'stores/site';

const ErrorFallback: FC = () => {
  return (
    <div className="flex flex-1 flex-col items-center justify-center">
      <div className="card shadow-lg p-10 gap-5">
        <h1>Something went wrong!</h1>
        <a href={`/${$site?.actions?.getClient()}`}>
          <Button variant="solid" className="w-full">
            Go to Home
          </Button>
        </a>
      </div>
    </div>
  );
};

export default ErrorFallback;
