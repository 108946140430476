import { FC, useMemo } from 'react';

import { lazyImport } from 'utils';

import { RoutePaths } from 'constants/routes';
import { Outlet, RouteObject, useRoutes } from 'react-router-dom';

import MainLayout from './MainLayout';
import Login from './login';
const Settings = lazyImport(() => import('./settings'));

const NotFound = lazyImport(() => import('./notfound'));
const ComingSoon = lazyImport(() => import('./ComingSoon'));

//PLOP_TEMPLATE: ROUTE IMPORTS
const PublicLandingScreen = lazyImport(() => import('./publiclandingscreen'));
const ItemDetail = lazyImport(() => import('./itemdetail'));

const CreateExhibit = lazyImport(() => import('./createexhibit'));
const Dashboard = lazyImport(() => import('./dashboard'));

const Inventory = lazyImport(() => import('./inventory'));
const Projects = lazyImport(() => import('./projects'));

const Exhibit = lazyImport(() => import('./exhibit'));
const CreateShipOrder = lazyImport(() => import('./createshiporder'));
const EditShipOrder = lazyImport(() => import('./editshiporder'));
const ShipOrdersSummary = lazyImport(() => import('./shiporderssummary'));

const ShipOrders = lazyImport(() => import('./shiporders'));
const AppRoutes: FC = () => {
  const routes = useMemo<RouteObject[]>(
    () => [
      {
        path: 'login',
        element: <Login />,
      },
      { path: '/', element: <PublicLandingScreen /> },
      {
        path: RoutePaths.client,
        element: <MainLayout />,
        children: [
          {
            path: 'ship-orders/create/item-detail/:itemId',
            element: <ItemDetail />,
          },
          {
            path: 'exhibit/create-exhibit',
            element: <CreateExhibit />,
          },

          {
            path: 'exhibits',
            element: <Exhibit />,
          },

          {
            path: 'ship-orders/create',
            element: <CreateShipOrder />,
          },
          {
            path: 'ship-orders/edit/:id',
            element: <EditShipOrder />,
          },
          {
            path: 'dashboard',
            element: <Dashboard />,
          },
          {
            path: 'inventory',
            element: <Inventory />,
          },
          {
            path: 'inventory/items',
            element: <Inventory />,
          },

          {
            path: 'ship-orders',
            element: <ShipOrders />,
          },
          {
            path: 'ship-orders/summary/:id',
            element: <ShipOrdersSummary />,
          },
          {
            path: 'projects',
            element: <Projects />,
          },
          { path: 'projects/product-display', element: <ComingSoon /> },
          { path: 'settings', element: <Settings /> },
          { path: 'comingsoon', element: <ComingSoon /> },

          // // should be the last
          { path: '*', element: <NotFound /> },
        ],
      },
    ],
    [],
  );

  const element = useRoutes(routes);

  return <>{element}</>;
};

export default AppRoutes;
