import React, { useEffect } from 'react';

import { LoginProps } from './Login.props';
import { $session } from 'stores';

import LoginView from './Login.view';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { login } from 'services/auth';
import { toast } from 'react-toastify';
import { useClientNavigate } from 'utils/clientRouter';
import AuthMiddleWareView from 'components/core/AuthMiddleWare';
const LoginContainer = (props: LoginProps) => {
  const clientNavigate = useClientNavigate();
  const navigate = useNavigate();
  const location = useLocation();

  const { mutate, isPending } = useMutation({
    mutationFn: login,
    onSuccess: ({ data }) => {
      const { accessToken, refreshToken, expiresIn } = data;
      const tokens = { accessToken, refreshToken, expiresIn };
      const email = data.email;
      $session.actions.setSession({
        tokens,
        email,
        userId: data.userId,
        clientId: data.clientId,
        client: data.client,
      });
      handleRedirect();
    },
    onError: (e: any) => {
      const errorMessage =
        e?.response?.data?.error?.message || 'Invalid email or password';
      toast.error(errorMessage, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        icon: false,
      });
    },
  });

  const handleRedirect = () => {
    const searchParams = new URLSearchParams(location.search);
    const from = searchParams.get('from');
    const search = searchParams.get('search') || '';

    if (from != null) {
      const redirect = `${from}${search}`;

      navigate(redirect, {
        replace: true,
      });

      return;
    }

    clientNavigate('ship-orders');
  };

  return (
    <AuthMiddleWareView>
      <LoginView isLoading={isPending} onSubmit={mutate} />
    </AuthMiddleWareView>
  );
};

export default LoginContainer;
