import { FC, useEffect } from 'react';

import { AuthMiddleWareProps } from './AuthMiddleWare.props';
import { RoutePaths } from '../../../constants/routes';
import { $session } from 'stores';
import { useClientNavigate } from 'utils/clientRouter';

const AuthMiddleWareView: FC<AuthMiddleWareProps> = ({
  children,
  handleRedirect,
}) => {
  const isAuthenticated = $session.selectors.useIsAuth();
  const navigate = useClientNavigate();
  useEffect(() => {
    if (handleRedirect) {
      handleRedirect(isAuthenticated);
    } else {
      if (isAuthenticated) {
        navigate(RoutePaths.shiporders);
      }
    }
  }, [isAuthenticated]);
  return children;
};

export default AuthMiddleWareView;
