import { useQuery } from '@tanstack/react-query';
import { getClientDetails } from 'services/auth';

const useClientDetails = (clientId: string) => {
  return useQuery({
    queryKey: ['client-details', clientId],
    queryFn: () => getClientDetails(clientId),
  });
};

export default useClientDetails;
