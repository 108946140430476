export const RoutePaths = {
  client: '/:currentClientId',
  landing: '/',
  login: '/login',
  settings: '/settings',
  //PLOP_TEMPLATE: ROUTE PATHS
  itemdetail: '/ship-orders/create/item-detail/',
  createexhibit: '/exhibit/create-exhibit',
  dashboard: '/dashboard',
  inventory: '/inventory',
  projects: '/projects',
  //SUB-ROUTES

  /* ship orders */
  shiporders: '/ship-orders',
  createshiporder: '/ship-orders/create',
  editshiporder: '/ship-orders/edit/',
  shiporderssummary: '/ship-orders/summary',

  //Inventory
  itemlist: '/inventory/add-items',
  items: '/inventory/items',
  //Projects
  exhibit: '/exhibits',
  productDisplay: '/projects/product-display',
  comingsoon: 'comingsoon',
} as const;

export const AuthRoutePaths = Object.values(RoutePaths).filter(
  (path) => path === RoutePaths.login,
) as string[];

export const MainRoutePaths = Object.values(RoutePaths)
  .filter((path) => path !== RoutePaths.landing)
  .filter((path) => path !== RoutePaths.login) as string[];
