import { FC } from 'react';

const LoadingFallback: FC = () => {
  return (
    <div className="flex flex-1 flex-col justify-center items-center">
      <span className="loading loading-spinner loading-lg bg-secondary-500"></span>
    </div>
  );
};

export default LoadingFallback;
