import React from 'react';

import AppRoutes from 'routes';
import AppProvider from 'providers/app';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';

import { BrowserRouter } from 'react-router-dom';
import { extractClientIdFromPath, extractTenantFromUrl } from 'utils/siteUtils';
import { $session } from 'stores';

extractTenantFromUrl();

function App() {
  return (
    <AppProvider>
      <BrowserRouter>
        <AppRoutes />
        <ToastContainer />
      </BrowserRouter>
    </AppProvider>
  );
}

export default App;
