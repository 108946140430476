import { FC } from 'react';

import { Link, useLocation } from 'react-router-dom';

import { cn } from 'utils';
import { $session } from 'stores';

import { RoutePaths } from 'constants/routes';

import { withAuthGuard } from 'components/hoc';
import powered_by from 'assets/powered_by.png';
import logo from 'assets/logo.png';
import { NavItems } from 'constants/sideBarMenuItems';

const MobileNavBar: FC = () => {
  return (
    <header className="navbar relative bg-white h-min shadow-lg">
      <Drawer />
      <Menu />
    </header>
  );
};

const Menu: FC = () => {
  const onLogout = () => {
    $session.actions.clearSession();

    window.location.assign('/');
  };

  return (
    <div className="dropdown dropdown-end absolute right-2 top-2 z-10">
      <div
        tabIndex={0}
        role="button"
        className="btn btn-ghost btn-circle avatar">
        <div className="w-14 rounded-full">
          <img
            alt="Tailwind CSS Navbar component"
            src="https://daisyui.com/images/stock/photo-1534528741775-53994a69daeb.jpg"
          />
        </div>
      </div>
      <ul
        tabIndex={0}
        className="mt-3 z-[1] p-4 shadow menu menu-sm dropdown-content bg-white rounded-box w-52">
        <li className="font-bold text-2xl font-sans">
          <Link className="justify-between py-2" to={RoutePaths.settings}>
            SETTINGS
          </Link>
        </li>
        <li className="font-bold text-2xl font-sans" onClick={onLogout}>
          <a className="justify-between py-2" href="#/">
            LOGOUT
          </a>
        </li>
      </ul>
    </div>
  );
};

const Drawer: FC = () => {
  const { pathname } = useLocation();

  return (
    <div className="drawer absolute left-2 top-2 z-10">
      <input id="my-drawer" type="checkbox" className="drawer-toggle" />
      <div className="drawer-content">
        <label htmlFor="my-drawer" className="btn btn-primary drawer-button">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            className="inline-block w-5 h-5 stroke-current">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"></path>
          </svg>
        </label>
      </div>
      <div className="drawer-side">
        <label
          htmlFor="my-drawer"
          aria-label="close sidebar"
          className="drawer-overlay"></label>
        <div className="menu p-4 w-80 min-h-full bg-base-200 text-base-content">
          <div className="flex flex-1 items-center justify-center max-h-[8vw] min-h-[100px] h-full bg-slate-300">
            <h1>
              {' '}
              <img src={logo} alt="imglogo" />
            </h1>
          </div>
          <ul className="flex flex-1 flex-col">
            {NavItems.map((navItem: any) => (
              <li
                key={navItem.path + navItem.title}
                className="w-full"
                onClick={() => {
                  const drawerElement = document.getElementById('my-drawer');

                  if (drawerElement) {
                    drawerElement.click?.();
                  }
                }}>
                <Link
                  to={navItem.path}
                  className={cn(
                    'btn btn-ghost btn-md h-[60px] items-center content-center justify-start font-sans text-primary-700 rounded-none focus:bg-primary-500 focus:text-white',
                    pathname === navItem.path
                      ? 'bg-primary-500 text-white'
                      : '',
                  )}>
                  {navItem.icon}
                  <div className="flex flex-1 max-w-[70%]">{navItem.title}</div>
                </Link>
              </li>
            ))}
          </ul>
          <div className="h-[200px] w-full flex flex-col gap-2 items-center justify-center">
            <div className="h-[100px] w-[120px] bg-primary-600">
              <img src={powered_by} alt="poweredby" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withAuthGuard(MobileNavBar);
