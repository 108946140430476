import React, { FC, useState } from 'react';
import { InputProps } from './Input.props';
import { cn } from 'utils';
import { useField } from 'formik';
import Text from '../Text';
import eyeIcon from 'assets/SVG/eye.svg';
import eyeOffIcon from 'assets/SVG/eye-off.svg';

const PasswordInput: FC<InputProps> = ({
  label,
  className,
  labelContainer,
  inputContainer,
  name,
  value,
  type = 'text',
  ...inputProps
}) => {
  const [field, meta, helpers] = useField(name);
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={cn(`flex flex-col gap-2 ${className}`)}>
      <div className={labelContainer}>
        <label htmlFor={inputProps.id}>{label}</label>
      </div>
      <div className={cn(`relative flex flex-col ${inputContainer}`)}>
        <input
          value={field?.value || value || ''}
          name={name}
          type={showPassword ? 'text' : type}
          {...inputProps}
          className={cn(
            `input input-bordered w-full max-w-xs ${inputContainer}`,
          )}
          onChange={(e) => {
            helpers.setValue(e.target?.value || '');
            const value = e.target?.value || '';
            if (inputProps.onChange) inputProps.onChange(value);
          }}
        />
        {type === 'password' && (
          <span
            className="absolute right-3 top-3 cursor-pointer"
            onClick={handleTogglePassword}>
            <img
              src={showPassword ? eyeIcon : eyeOffIcon}
              alt={showPassword ? 'Hide password' : 'Show password'}
              style={{ width: '20px', height: '20px' }}
            />
          </span>
        )}
        {meta.error && meta.touched && (
          <Text className="text-red-500">{meta.error}</Text>
        )}
      </div>
    </div>
  );
};

export default PasswordInput;
