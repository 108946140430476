import { FC, useState } from 'react';

import { animated, config, useSpring } from '@react-spring/web';

import { withAuthGuard } from 'components/hoc';
import logo from 'assets/logo-primary.webp';
import powered_by from 'assets/poweredbyXH.svg';
import LogoutIcon from 'assets/SVG/logOutIcon';
import { sideBarMenuItems } from 'constants/sideBarMenuItems';
import SidebarItem from 'components/primitives/SidebarItem';
import clientLogo from 'assets/client-logo.png';
import { useLogout } from 'hooks/useLogout';
import { getClientName } from 'utils/siteUtils';
import $clientStore from 'stores/client/store';
import { getImageUrl } from 'services/instance';

const STYLES = {
  from: {
    x: -300,
    opacity: 0,
  },
  to: {
    x: 0,
    opacity: 1,
  },
  config: config.default,
};

const SideBar: FC = () => {
  const [selectedElementKey, setSelectedElementKey] = useState<string>();
  const styles = useSpring(STYLES);
  const handleLogout = useLogout();
  const clientLogo = $clientStore.actions.getLogo();
  const clientName = $clientStore.actions.getName();

  return (
    <animated.aside
      className="flex shadow-xl z-50 shadow-gray-300 flex-col bg-neutralBg w-[15vw] min-w-[240px] h-screen"
      style={styles}
      role="navigation">
      <div className="flex flex-1 items-center justify-center max-h-[10vw] min-h-[130px] h-full">
        <img alt="imglogo" src={logo} width="150px" />
      </div>
      <div className="divider"></div>
      <h3 className="capitalize flex justify-center text-center font-bold">
        {clientLogo ? (
          <img width={150} src={getImageUrl(clientLogo!)} alt="" />
        ) : (
          clientName
        )}
      </h3>
      <div className="divider"></div>
      <ul className="flex flex-col items-center gap-7">
        {[...sideBarMenuItems].map(
          ({ title, path, items, Icon }, idx: number) => {
            const KEY = `menu-item-${title}-${idx}`;
            const IS_SELECTED = KEY === selectedElementKey;

            return (
              <SidebarItem
                key={KEY}
                path={path ?? ''}
                title={title}
                Icon={Icon}
                options={items}
              />
            );
          },
        )}
        {/* Logout button */}

        <div
          onClick={handleLogout}
          className="flex btn btn-ghost btn-md w-48 ml-3 justify-start h-auto font-primary text-primary-350 rounded-none hover:bg-blue-50 hover:text-blue-200">
          <LogoutIcon width={20} height={20} />
          Logout
        </div>
      </ul>

      <div className="h-[200px] w-full flex flex-col gap-2 items-center justify-center">
        <div className="h-[100px] w-[120px]">
          {' '}
          <img alt="poweredBy" src={powered_by} />
        </div>
      </div>
    </animated.aside>
  );
};

export default withAuthGuard(SideBar);
