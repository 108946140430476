import { LoginPayload, LoginResponse, User } from 'types/auth';
import { api } from './instance';

export const login = async (payload: LoginPayload) => {
  const { data } = await api.post<LoginResponse>(`/auth/login-client`, payload);
  return data;
};

export const getClientDetails = async (id: string) => {
  const { data } = await api.get(`/client/detail/${id}`);
  return data;
};
