// utils.js

import { $site } from 'stores/site';

// Function to extract subdomain from URL using regex
export const extractTenantFromUrl = (): string | null => {
  const url = window.location.href;
  // Default value for localhost
  if (url.includes('localhost')) {
    $site.actions.setTenant('triple20');
    return 'triple20';
  }
  const regex = /(?:https?:\/\/)?(?:www\.)?(?:stage-)?([^.]*)\./;
  const match = url.match(regex);

  if (match && match[1]) {
    $site.actions.setTenant(match[1]);
    return match[1];
  } else {
    return null;
  }
};

export const extractClientIdFromPath = (pathname: string): string => {
  const parts = pathname.split('/');
  const client = parts[1] || 'acme-corporation'; // Assuming the client ID is the first path segment
  $site.actions.setClient(client);
  return client;
};

export const getClientName = () => {
  const clientId = extractClientIdFromPath(window.location.pathname);
  //create a name from splting by -
  return clientId.split('-').join(' ');
};
