import React from 'react';
import { IconProps } from './iconProps';

const ArrowDownIcon = ({ fill, w = '12', h = '12', className }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={w}
      height={h}
      className={`${className}`}
      viewBox="0 0 24 24">
      <path strokeWidth="3" d="M19 9l-7 7-7-7"></path>
    </svg>
  );
};

export default ArrowDownIcon;
