import React, { useEffect, useState } from 'react';
import MenuItem from './MenuItem';
import MenuSubItem from './MenuSubItem';
import { SubMenuItemProps } from 'constants/sideBarMenuItems';

export type MenuDropdownProps = {
  isSelected: boolean;
  title: string;
  path: string;
  currentPath: string;
  Icon?: React.ElementType;
  options: Array<SubMenuItemProps>;
  isDropdown: boolean;
  onItemClick?: (_path: string) => void;
};

const MenuDropdown = ({
  currentPath,
  isSelected,
  title,
  Icon,
  options,
  isDropdown,
}: MenuDropdownProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <MenuItem
      path=""
      title={title}
      Icon={Icon}
      isOpen={isOpen}
      isSelected={isSelected}
      isDropdown={isDropdown}
      onItemClick={() => setIsOpen((p) => !p)}>
      {isOpen && (
        <ul className="flex flex-col w-full ml-0 py-4">
          {options?.map(({ itemTitle, path }) => {
            const IS_SELECTED = path === currentPath;

            return (
              <MenuSubItem
                key={`menu-sub-item-${itemTitle}`}
                itemTitle={itemTitle}
                path={path}
                getPath={() => ''}
                isSelected={IS_SELECTED}
              />
            );
          })}
        </ul>
      )}
    </MenuItem>
  );
};

export default MenuDropdown;
