import React, { FC } from 'react';
import { cn } from 'utils';

import { TextProps } from './Text.props';

const TextView: FC<TextProps> = ({ className, ...props }) => {
  return (
    <span className={cn(`text-jakarta text-sm ${className}`)} {...props} />
  );
};

export default TextView;
