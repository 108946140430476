import React from 'react';

import { $session } from 'stores';

function withAuthGuard<T extends React.FC<any>>(Component: T) {
  const AuthGuard: React.FC<any> = (props) => {
    const isAuth = $session.selectors.useIsAuth();

    if (!isAuth) {
      return null;
    }

    return <Component {...props} />;
  };

  return AuthGuard;
}

export default withAuthGuard;
