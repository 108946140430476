import { SubMenuItemProps } from 'constants/sideBarMenuItems';
import React, { useMemo, useState } from 'react';
import MenuDropdown from './MenuDropdown';
import MenuItem from './MenuItem';
import { useLocation, useNavigate } from 'react-router-dom';
import { useClientNavigate } from 'utils/clientRouter';

export type SidebarItemProps = {
  title: string;
  Icon?: React.ElementType;
  path: string;
  options?: SubMenuItemProps[];
};

const SidebarItem = (props: SidebarItemProps) => {
  const { title, path, Icon, options = [] } = props;
  const [isDropdown] = useState(options?.length > 0);

  const navigate = useClientNavigate();
  const { pathname } = useLocation();

  const IS_SELECTED = useMemo(
    () => pathname === path,
    [isDropdown, path, pathname],
  );

  const onNavigate = (path: string) => navigate(path);

  if (isDropdown) {
    return (
      <MenuDropdown
        title={title}
        path={path}
        currentPath={pathname}
        Icon={Icon}
        isSelected={IS_SELECTED}
        options={options}
        isDropdown={isDropdown}
      />
    );
  }

  return (
    <MenuItem
      title={title}
      path={path}
      Icon={Icon}
      isSelected={IS_SELECTED}
      onItemClick={onNavigate}
      isDropdown={isDropdown}
    />
  );
};

export default SidebarItem;
