import { useNavigate } from 'react-router-dom';
import { $session } from 'stores';
import { $cart } from 'stores/cart';
import $clientStore from 'stores/client/store';
import { queryClient } from 'utils';
import { useClientNavigate } from 'utils/clientRouter';

export const useLogout = () => {
  const navigate = useClientNavigate();

  const clearSession = () => {
    $session.actions.clearSession();
    $cart.actions.clearCart();
    $clientStore.actions.clearStore();
    queryClient.invalidateQueries();
    navigate('/');
  };

  return clearSession;
};
