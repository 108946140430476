import React from 'react';
import { SubMenuItemProps } from 'constants/sideBarMenuItems';
import { Link } from 'react-router-dom';
import { cn } from 'utils';

type MenuSubItemProps = SubMenuItemProps & {
  isSelected: boolean;
};

const MenuSubItem = ({ path, itemTitle, isSelected }: MenuSubItemProps) => {
  return (
    <li className={`flex flex-col justify-start`}>
      <Link
        to={`${path}`}
        className={cn(
          'flex justify-start text-primary-350 bg-transparent hover:text-blue-200 hover:bg-transparent hover:font-bold active:font-bold',
        )}>
        <span
          className={`text-primary-350 text-left text-lg font-sans font-bold hover:text-blue-200`}>
          {itemTitle}
        </span>
      </Link>
    </li>
  );
};

export default MenuSubItem;
