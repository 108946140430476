import { useEffect, useState } from 'react';

// Define a breakpoint for mobile devices
const MOBILE_BREAKPOINT = 768;

/**
 * The `useIsMobile` function is a custom React hook that returns a boolean value indicating whether
 * the current screen width is less than a specified mobile breakpoint.
 * `MOBILE_BREAKPOINT=760`
 * @returns The `useIsMobile` custom hook returns a boolean value indicating whether the current screen
 * width is considered as a mobile device.
 */
function useIsMobile() {
  const [isMobile, setIsMobile] = useState(
    () => window.innerWidth < MOBILE_BREAKPOINT,
  );

  useEffect(() => {
    const checkIfMobile = () => {
      // Use window.innerWidth for screen width based detection
      const screenWidth = window.innerWidth;
      setIsMobile(screenWidth < MOBILE_BREAKPOINT);
    };

    window.addEventListener('resize', checkIfMobile);

    // Cleanup listener on unmount
    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  return isMobile;
}

export default useIsMobile;
