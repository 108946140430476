import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

type State = {
  tenant: string | null;
  client: string | null;
};

const useSiteStore = create(
  immer<State>(() => ({
    tenant: null,
    client: null,
  })),
);

const selectors = {};

const actions = {
  setTenant: (id: string) => {
    const set = useSiteStore.setState;
    set((state) => {
      state.tenant = id;
    });
  },
  setClient: (id: string) => {
    const set = useSiteStore.setState;
    set((state) => {
      state.client = id;
    });
  },
  clearSession: () => {
    const set = useSiteStore.setState;
    set((state) => {
      state.tenant = null;
      state.client = null;
    });
  },
  // getters
  getTenant: () => useSiteStore.getState().tenant,
  getClient: () => useSiteStore.getState().client,
};

/**
 * **Session Store**
 *
 * this store is used to store session tokens
 *
 * **"$"** is a convention to indicate that this is a store
 */
const $site = {
  actions,
  selectors,
} as const;

export default $site;
