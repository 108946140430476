import React from 'react';

import { animated, config, useSpring } from '@react-spring/web';

function withPageMotion<T extends React.FC<any>>(Component: T) {
  const PageMotion: React.FC<any> = (props) => {
    const styles = useSpring({
      from: { opacity: 0 },
      to: { opacity: 1 },
      config: config.slow,
      immediate: false,
    });

    const className = `flex flex-1 flex-col`;

    return (
      <animated.div style={styles} className={className}>
        <Component {...props} />
      </animated.div>
    );
  };

  return PageMotion;
}

export default withPageMotion;
