import {
  useNavigate as mainUseNavigate,
  NavigateOptions,
} from 'react-router-dom';
import { $session } from 'stores';
import $clientStore from 'stores/client/store';

/**
 * This client navigate function is created to append the client-id to every route.
 * It helps in separating out public routes from the private routes, ensuring that
 * the client name prefix is not added to all the routes.
 *
 * Usage:
 * - For private routes, the client-id will be automatically prefixed to the path.
 * - For public routes, the client-id will not be prefixed.
 *
 * Example:
 * - Private route: navigate('dashboard') -> /client-id/dashboard
 * - Public route: navigate('/login') -> /login
 */

export const useClientNavigate = () => {
  const navigate = mainUseNavigate();
  const client = $clientStore.actions.getCode();
  return (path: string, options?: NavigateOptions) => {
    navigate(`/${client}/${path}`.replace(/\/{2,}/g, '/'), options);
  };
};
