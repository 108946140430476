import { CartItem } from 'types/cart';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

type State = {
  cart: CartItem[] | [];
};

const useCartStore = create(
  immer<State>(() => ({
    cart: [],
  })),
);

const store = useCartStore;

const selectors = {
  useCartItems: () => store((state) => state.cart),
};

const actions = {
  addToCart: (cartItem: CartItem) => {
    const set = store.setState;
    const cart = store.getState().cart;
    const existingIndex = cart.findIndex(
      (item) => item.item._id === cartItem.item._id,
    );

    if (existingIndex !== -1) {
      // If item already exists, replace it
      const updatedCart = [...cart];
      updatedCart[existingIndex] = cartItem;
      set({ cart: updatedCart });
    } else {
      // If item doesn't exist, add it to the cart
      set({ cart: [...cart, cartItem] });
    }
  },
  removeFromCart: (itemId: string) => {
    store.setState((state) => {
      state.cart = state.cart.filter((item) => item.item._id !== itemId);
    });
  },
  clearCart: () => {
    const set = useCartStore.setState;
    set((state) => {
      state.cart = [];
    });
  },
  // getters
  getCart: () => useCartStore.getState().cart,
};

const subscribe = useCartStore.subscribe;

/**
 * **Setting Store**
 *
 * this store is used to store setting data
 *
 * **"$"** is a convention to indicate that this is a store
 */
const $cart = {
  actions,
  selectors,
  subscribe,
};

export default $cart;
