import React, { useMemo, useState } from 'react';
import ArrowDownIcon from 'svg/angleDownArrowIcon';

export type MenuItemProps = {
  isOpen?: boolean;
  isSelected: boolean;
  title: string;
  path: string;
  Icon?: React.ElementType;
  isDropdown: boolean;
  children?: React.ReactNode;
  onItemClick?: (_path: string) => void;
};

const MenuItem = ({
  Icon,
  title,
  isSelected,
  isOpen,
  path,
  isDropdown,
  children = null,
  onItemClick = (_path: string) => {},
}: MenuItemProps) => {
  const IS_DROPDOWN = useMemo(() => !!children, [children]);
  const BG_STYLE = isSelected ? 'bg-blue-50 h-auto' : 'bg-transparent';

  const ITEM_ICON = useMemo(
    () =>
      Icon && (
        <Icon
          w={'16'}
          h={'16'}
          className={`${isOpen || isSelected ? 'fill-blue-200 stroke-blue-200' : 'fill-primary-350 stroke-primary-400'}`}
        />
      ),
    [isOpen, isSelected],
  );
  const DROPDOWN_ICON = useMemo(() => {
    if (!isDropdown) return <></>;

    return (
      <div
        itemType="icon"
        className={`absolute right-0 transform transition-transform ${isOpen ? 'rotate-180' : ''}`}>
        <ArrowDownIcon
          className={`w-4 h-4 ${isOpen ? 'fill-blue-200 stroke-blue-200' : 'fill-primary-350 stroke-primary-400'} active:fill-blue-200 after:fill-blue-200`}
        />
      </div>
    );
  }, [IS_DROPDOWN]);

  return (
    <div
      className={`menu w-48 h-auto ${BG_STYLE} hover:text-blue-200 fill-blue-200 hover:bg-blue-50 flex justify-center lg:menu-horizontal rounded-lg cursor-pointer`}>
      <div
        className="w-44 flex flex-col justify-center items-center hover:bg-blue-50 border-none"
        onClick={() => onItemClick(path)}>
        <div className="w-36 flex justify-start hover:fill-blue-200 gap-2 items-center hover:bg-blue-50 relative">
          {ITEM_ICON}
          <span
            className={`${isOpen || isSelected ? 'text-blue-200 font-bold' : 'text-primary-350 font-normal'} text-lg font-primary`}>
            {title}
          </span>
          {DROPDOWN_ICON}
        </div>
        {children}
      </div>
    </div>
  );
};

export default MenuItem;
