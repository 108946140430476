import { CSSProperties, FC, PropsWithChildren } from 'react';

import { withPageMotion } from 'components/hoc';

import { cn } from 'utils';

const PageLayout: FC<
  PropsWithChildren<{ className?: string; style: CSSProperties }>
> = ({ children, className, style }) => {
  return (
    <div className={cn('flex flex-1 flex-col', className)} style={style}>
      {children}
    </div>
  );
};

export default withPageMotion(PageLayout);
